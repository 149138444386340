window.fbAsyncInit = function() {
    var d = document.location;
    var url = encodeURIComponent(d.origin + d.pathname);
    console.log(url);

    FB.init({
        appId:		'3871045186323705',
        status:		true,
        xfbml:		true,
        version:    'v2.1'
    });

    FB.api(
        '/?id='+url+'&scrape=true',
        'post',
        function(response) {
            if (!response || response.error) {
                throw (response);
            } else {
                console.log('Successful!', response);
            }
        });
};
(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/cs_CZ/all.js";
    //js.src = "//connect.facebook.net/en_US/sdk/debug.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));